import { isDebug } from './debug';
import { getParam } from './getParam';

/**
 * Logs the title and stuff when isDebug is true
 */
export function debug(title, ...stuff) {
  if (isDebug()) {
    console.log('%cAudi Exclusive', 'background: #f09f13; color: #080808; padding: 4px 14px; border-radius: 5px; font-weight: bold;', title, '\n', ...stuff);
  }
}

/**
 * Logs the title and stuff when the GET param is present together with the debug param
 *
 * e.g. this will work for the following URL: http://localhost:3333/?debug=1&translations=1
 *  ```
 *  conditionalDebug('translations', 'Translations', state.translations);
 *  ```
 */
export function conditionalDebug(param: string, title, ...stuff) {
  if (getParam(param)) {
    debug(title, ...stuff);
  }
}
