import { getParam } from './getParam';

/**
 *
 * @returns true if the debug GET parameter is present
 */
export function isDebug() {
  return !!getParam('debug');
}

export function noStream() {
  return !!getParam('nostream');
}
