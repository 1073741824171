/**
 * Obtain value of a GET parameter from the URL
 * For example, if the URL is https://example.com/?foo=bar
 *  getParam('foo') // "bar"
 *
 * This function relies on native functions and is much more lightweight than query-strings
 * @param paramName GET parameter name
 */
export function getParam(paramName: string): string | null {
  try {
    const searchParams = new URLSearchParams(globalThis.location.search.substr(1));
    return searchParams.get(paramName);
  } catch (e) {
    return null;
  }
}
