import { createStore } from '@stencil/store';
import { AudiExclusivePackage, AudiExclusivePackageCode } from '../config/package';
import StreamController from '../stream';
import { TranslationCatalog } from '../utils/TranslationsService';
import { getParam } from '../utils/getParam';
import { debug } from '../utils/log';

// example change:
/*
{
  "YZ1": { // <- menu ID
    "LI_ID01": { // <- part ID
      "dcc_text_en": "Door insert",
      "color_en": "Crimson red",
      "ae_color": "AEIC010",
      "mat_group": "Alcantar",
      "abi_text": "Türinstert",
      "dcc_text_de": "Türspiegel",
      "color_de": "Karmesinrot",
      "id": "LI_ID01",
      "pr_rule": "",
      "image_url": "https://d2sbkzoafao45f.cloudfront.net/YZ1/LI_ID01/AEIC010.png",
      "prValue": "LI_ID01_AEIC010"
    }
  }
}
*/

export type PartId = string;

export type UnknownPackage = {
  dcc_text_en?: string;
  color_en?: string;
  ae_color?: string;
  mat_group?: string;
  abi_text?: string;
  dcc_text_de?: string;
  color_de?: string;
  id?: string;
  pr_rule?: string;
  image_url?: string;
  is_active?: boolean | string;
  prValue?: string;
};

export type MenuChange = Record<PartId, UnknownPackage>;

export type UserChanges = Partial<Record<AudiExclusivePackageCode, MenuChange>>;

export type ConfiguratorStore = {
  prString: string;
  package?: AudiExclusivePackage;
  carModel: string;
  stream: StreamController;
  changes: UserChanges;
  translations: TranslationCatalog;
  locale: string;
};

const stateDefaults: ConfiguratorStore = {
  prString: '',
  package: undefined,
  carModel: '4MT',
  stream: undefined,
  changes: {},
  translations: {},
  locale: 'en',
};

// Every one of state defaults can be overridden from _GET variables
// for example: ?prString=123&carModel=4MT&locale=de
function getStateDefaults() {
  const prString = getParam('prString') ?? stateDefaults.prString;
  const carModel = getParam('carModel') ?? stateDefaults.carModel;
  const locale = getParam('locale') ?? stateDefaults.locale;

  const state = {
    ...stateDefaults,
    prString,
    carModel,
    locale,
  };

  debug('Initial state', JSON.stringify(state, null, 2));
  debug('Debug URL', `http://localhost:3333/?prString=${prString}&carModel=${carModel}&locale=${locale}`);

  return state;
}

const store = createStore<ConfiguratorStore>(getStateDefaults());

export function saveOptions(options: MenuChange) {
  store.set('changes', { ...store.get('changes'), [store.get('package').packageCode]: options });
}

export function getTranslation(translationKey: string): string {
  if (!translationKey) return '';
  let key = translationKey?.toLowerCase?.() ?? '';
  key = key.trim();

  return store.state.translations[key] || translationKey;
}

export default store;
export const state = store.state;
